import KarolinaWilk from '../images/mpk/prelegent/karolinawilk.jpeg';
import Kosecki from '../images/mpk/prelegent/Kosecki_pic.jpg';
import Ahmad from '../images/mpk/prelegent/ahmad.jpg';
import kaczorowska from '../images/mpk/prelegent/kaczorowska.jpg';
import majewska from '../images/mpk/prelegent/majewska.jpg';
import walkowski from '../images/mpk/prelegent/walkowski.jpg';
import mejszutowicz from '../images/mpk/prelegent/mejszutowicz.jpg';
import sugier from '../images/mpk/prelegent/sugier.jpg';
import nalbert from '../images/mpk/prelegent/nalbert.jpg';
import raczynski from '../images/mpk/prelegent/raczynski.jpg';
import malolepszy from '../images/mpk/prelegent/malolepszy.jpg';
import stanek from '../images/mpk/prelegent/stanek.jpg';
import frankowski from '../images/mpk/prelegent/frankowski.jpg';
import younis from '../images/mpk/prelegent/younis.jpg';
import majewski from '../images/mpk/prelegent/majewski.jpg';
import sobczyk from '../images/mpk/prelegent/sobczyk.jpg';
import szyszkowski from '../images/mpk/prelegent/szyszkowski.jpg';
import kalinowski from '../images/mentoring/jurors/jerzy-kalinowski.jpg';
import raciborski from '../images/mentoring/jurors/kacper-raciborski.jpg';
import martyniuk from '../images/mentoring/mentors/martyniuk.jpg';
import kazimierczak from '../images/mpk/prelegent/kazimierczak.jpg';

const MPKSpeakers = [
  {
    name: 'Ahmad Piraiee',
    title: 'Dyrektor w Startup Grind',
    image: Ahmad
  },
  {
    name: 'Dariusz Mejszutowicz',
    title: 'Wicedyrektor Działu Rynku Pierwotnego w GPW',
    image: mejszutowicz
  },
  {
    name: 'Dawid Sugier',
    title: 'Associate w Inovo.vc',
    image: sugier
  },
  {
    name: 'Ireneusz Martyniuk',
    title: 'Dyrektor inwestycyjny & Partner w Invento Capital',
    image: martyniuk
  },
  {
    name: 'Jan Nalbert',
    title: 'Investment Manager',
    image: nalbert
  },
  {
    name: 'Jan Raczyński',
    title: 'Partner zarządzający Rebels Valley',
    image: raczynski
  },
  {
    name: 'Jerzy Kalinowski',
    title: 'Anioł Biznesu',
    image: kalinowski
  },
  {
    name: 'Jędrzej Małolepszy',
    title: 'Prezes i współzałożyciel DoubleData',
    image: malolepszy
  },
  {
    name: 'Kacper Raciborski',
    title: 'Prezes Fundacji Innovations Hub',
    image: raciborski
  },
  {
    name: 'Karolina Kazimierczak',
    title: 'Zastępca Dyrektora Departamentu Rozwoju Startupów w PARP',
    image: kazimierczak
  },
  {
    name: 'Karolina Wilk-Tryjanowska',
    title: 'Platform Manager w ffVC',
    image: KarolinaWilk
  },
  {
    name: 'Konrad Kosecki',
    title: 'Selection Expert w Akces NCBR',
    image: Kosecki
  },
  {
    name: 'Maria Tyka-Majewska',
    title: 'Senior Program Manager w Huge Thing',
    image: majewska
  },
  {
    name: 'Michał Stanek',
    title: 'Prezes QValue S.A.',
    image: stanek
  },
  {
    name: 'Mariusz Frankowski',
    title: 'Dyrektor w Mazowieckiej Jednostce Wdrażania Programów UE',
    image: frankowski
  },
  {
    name: 'Marcin Walkowski',
    title: 'Project Manager',
    image: walkowski
  },
  {
    name: 'Mikołaj Szyszkowski',
    title: 'Dyrektor Biura Projektów i Produktów Kapitałowych',
    image: szyszkowski
  },
  {
    name: 'Paulina Kimla-Kaczorowska',
    title: 'Partner Lokalny Greenberg Traurig',
    image: kaczorowska
  },
  {
    name: 'Piotr Majewski',
    title: 'Prezes FindFunds S.A.',
    image: majewski
  },
  {
    name: 'Piotr Sobczyk',
    title: 'Ekspert rozwoju biznesu i pozyskiwania kapitału',
    image: sobczyk
  },
  {
    name: 'Tarek Younis',
    title: 'Prezes Kemgo',
    image: younis
  }
];

export default MPKSpeakers