import { graphql } from 'gatsby';
import MpkPage from '../views/mpk';

export default MpkPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
}`